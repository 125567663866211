import React         from 'react'
import { observer  } from 'mobx-react'

import pomodoroState from './state'

export const PomodoroSequence = observer(({}) => {
  const {plan,work,short,long,pattern,count} = pomodoroState.durations
  const planning = (pomodoroState.planning ? plan : 0)
  const total = pattern * work + (pattern-1) * short + long + pattern * planning
  const planWidth  = (100 * planning  / total)+'%'
  const workWidth  = (100 * work  / total)+'%'
  const shortWidth = (100 * short / total)+'%'
  const longWidth  = (100 * long  / total)+'%'
  return <div className="pomodoro-sequence">
    {Array.apply(null, {length: pattern-1}).map(Number.call, Number).map(i => <React.Fragment key={i}>
      <span className={'plan' + ((i   === count && pomodoroState.mode === 'plan')  ? ' active' : '')} style={{width: planWidth}} />
      <span className={'work' + ((i   === count && pomodoroState.mode === 'work')  ? ' active' : '')} style={{width: workWidth}} />
      <span className={'short'+ ((i+1 === count && pomodoroState.mode === 'short') ? ' active' : '')} style={{width: shortWidth}} />
    </React.Fragment>)}
    <span className={'plan' + ((pattern === count+1 && pomodoroState.mode === 'plan')  ? ' active' : '')} style={{width: planWidth}} />
    <span className={'work' + ((pattern === count+1 && pomodoroState.mode === 'work')  ? ' active' : '')} style={{width: workWidth}} />
    <span className={'long' + ((pomodoroState.mode === 'long')  ? ' active' : '')} style={{width: longWidth}} />
  </div>
})
