import React from 'react'

import { BookLink  } from './link'
import { BookCover } from './cover'
import { BookASIN  } from './asin'
import { BookTitle } from './title'

import './books.scss'

export const BookCard = ({asin,title}) =>
<span className="col-md-1 book-link book-details">
  <BookLink asin={asin}>
    <BookCover  asin={asin} />
    <BookASIN   asin={asin} />
    <BookTitle title={title} />
  </BookLink>
</span>
