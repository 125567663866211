import { action, observable } from 'mobx'

export class PomodoroState {

  @observable durations = {
    plan    : 300,
    work    : 1200,
    short   : 300,
    pattern : 4,
    count   : 0,
    long    : 900,
  }

  @observable current   = {}

  @observable settings  = false
  @observable planning  = true
  @observable running   = false
  @observable reference = 0
  @observable autoCycle = true
  @observable mode      = null

  constructor() {
    this.reset({
      hard : true,
    })()
  }

  @action
  update = () => {
    const elapsed  = new Date().valueOf() - this.reference
    const timeLeft = Math.floor(this.durations[this.mode] - elapsed / 1000)

    this.current   = {
      timeLeft : timeLeft,
      ticks    : 9 - Math.floor(elapsed / 100) % 10,
      elapsed  : elapsed,
    }

    if (timeLeft <= 0 && this.current.ticks === 0) {
      this.advance()
    }
  }

  @action
  advance = () => {
    this.pause()
    if (this.autoCycle) {
      this.cycle()
      this.cont()
    }
  }

  @action
  cycle = () => {
    if (this.mode === 'plan') {
      this.reset({
        mode : 'work',
      })()
    } else if (this.mode === 'work') {
      this.durations.count = this.durations.count + 1
      if (this.durations.count < this.durations.pattern) {
        this.reset({
          mode : 'short',
        })()
      } else {
        this.durations.count = 0
        this.reset({
          mode : 'long',
        })()
      }
    } else {
      if (this.planning) {
        this.reset({
          mode : 'plan',
        })()
      } else {
        this.reset({
          mode : 'work',
        })()
      }
    }
  }

  @action
  start = () => {
    if (!this.running && this.current.timeLeft > 0) {
      this.cont()
    }
  }
  @action
  stop = () => {
    if (this.running) {
      this.pause()
      this.reset({
        hard : true,
      })()
    }
  }
  adjust = (field,step) => action(() => {
    this.durations[field] = this.durations[field] + step
  })

  @action
  toggleSettings = () => {
    this.settings = !this.settings
  }

  reset = ({mode,hard}) => action(() => {
    if (!this.running) {
      this.mode = mode || (this.planning ? 'plan' : 'work')
      this.current = {
        timeLeft  : this.durations[this.mode],
        ticks     : 0,
        elapsed   : 0,
      }
      if (hard) {
        this.durations.count = 0
      }
      this.reference = new Date().valueOf()
    }
  })

  @action
  pause = () => {
    if (this.running) {
      this.running = false
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }
  @action
  cont = () => {
    if (!this.running) {
      this.reference = new Date().valueOf() - this.current.elapsed
      this.timer     = setInterval(this.update,100)
      this.running   = true
      this.settings  = false
    }
  }
}

export default new PomodoroState()
