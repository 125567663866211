import React               from 'react'
import { observer        } from 'mobx-react'

import { PomodoroConfig  } from './config'
import { PomodoroPattern } from './pattern'

import pomodoroState       from './state'

export const PomodoroSettings = observer(() => pomodoroState.settings ? <div className="pomodoro-settings">
  <PomodoroConfig  field="plan"  step={60} />
  <PomodoroConfig  field="work"    />
  <PomodoroConfig  field="short" step={60} />
  <PomodoroConfig  field="long"    />
  <PomodoroPattern field="pattern" />
</div> : null)
