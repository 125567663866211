import React         from 'react'
import { observer  } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import pomodoroState from './state'

export const PomodoroPhase = observer(()=> <div className="pomodoro-phase">
  <Label value={'pomodoro.phase.'+pomodoroState.mode} />
</div>)
