import React from 'react'
import { inject, observer  } from 'mobx-react'

import { PomodoroControls  } from './controls'
import { PomodoroPhase     } from './phase'
import { PomodoroSequence  } from './sequence'
import { PomodoroSettings  } from './settings'
import { PomodoroTime      } from './time'

import './panel.scss'

export const PomodoroPanel = inject('pomodoroState')(observer(({pomodoroState,...props}) => <div className="sdc-pomodoro-panel" {...props}>
  <PomodoroTime value={pomodoroState.current.timeLeft} ticks={pomodoroState.current.ticks} className={'pomodoro-time '+pomodoroState.mode} blink />
  <PomodoroSequence />
  <PomodoroPhase />
  <PomodoroControls />
  <PomodoroSettings />
</div>))
