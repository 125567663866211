import React from 'react'
import { inject, observer } from 'mobx-react'

import * as bl from './helpers'

import { BookLink    } from './link'
import { BookASIN    } from './asin'
import { BookCover   } from './cover'
import { AmazonBlurb } from './blurb'

import './books.scss'

@inject('adSlots')
@observer
export class BookAd extends React.Component {
  componentWillMount() {
    if (!this.props.asin) {
      this.props.adSlots.addSlot(this.slotID())
    }
  }
  componentWillUnmount() {
    if (!this.props.asin) {
      this.props.adSlots.delSlot(this.slotID())
    }
  }
  slotID() {
    return this.props.slot || 'xxx'
  }
  render() {
    const {adSlots,asin,style} = this.props
    const asin2use = asin || (adSlots.slots[this.slotID()] || {}).asin

    return <span className="pull-right book-link book-ad" style={style}>
      <BookLink asin={asin2use}>
        <BookCover asin={asin2use} />
        <BookASIN  asin={asin} />
        <AmazonBlurb showing={!asin} />
      </BookLink>
    </span>
  }
}
