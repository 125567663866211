import React from 'react'
import { observer } from 'mobx-react'

import './panel.scss'

export const PomodoroTime = observer(({value,className='',blink,ticks,...props}) => {
  const mins = Math.floor(value / 60)
  const secs = ('0'+value % 60).slice(-2)
  return <span className={'time-display '+className} {...props} >
    {mins}<span className={(blink && (ticks > 0 && ticks < 6)) ? 'dimmed' : ''}>:</span>{secs}
    {ticks !== undefined && <span className="ticks">.{ticks}</span>}
  </span>
})
