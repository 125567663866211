import { action } from 'mobx'
import _      from 'lodash'

import { ContentApi }    from 'sdc-cms-client'
import { StandardStore } from 'sdc-mobx-stores'

import { publish, subscribe } from 'sdc-publish-subscribe'

export class AdsStore extends StandardStore {

  constructor(api) {
    super({api})
    subscribe('ads.amazon.load',this.reload)
  }

  reload = () => {
    this.list({callback:this.parseData})()
  }

  parseData = deferred => action(payload => {
    const ads = payload[this.listName] || []
    const raw = ads.map(ad => ad.values)
    publish('ads.amazon.data', _.shuffle(raw))

    this.setEntries(deferred)(payload)
  })

}

export default (apiParams) => new AdsStore(ContentApi(apiParams))
