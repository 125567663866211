import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { TimeFormatter } from 'sdc-react-basics'

export const PomodoroConfig = inject('pomodoroState')(observer(({pomodoroState,field,step=300}) => pomodoroState.settings ?
<div className="time-settings">
  <span className="settings-label"><Label value={'pomodoro.config.'+field} /></span>
  <span className="settings-input">
    <i className="fa fa-plus"  onClick={pomodoroState.adjust(field,step)} />
    <i className="fa fa-minus" onClick={pomodoroState.adjust(field,-step)} />
    <TimeFormatter time={pomodoroState.durations[field]} />
  </span>
</div> : null))
