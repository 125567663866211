import React from 'react'
import { inject, observer } from 'mobx-react'

export const PomodoroControls = inject('pomodoroState')(observer(({pomodoroState}) => <div className="pomodoro-controls">
  <i className={'fa fa-play'+(pomodoroState.running || pomodoroState.current.timeLeft <= 0 ? ' disabled' : '')} onClick={pomodoroState.start} />
  <i className={'fa fa-pause'+(pomodoroState.running ? '' : ' disabled')}   onClick={pomodoroState.pause}/>
  <i className={'fa fa-step-forward'+(pomodoroState.running ? '' : ' disabled')}   onClick={pomodoroState.advance}/>
  &nbsp;&nbsp;
  <i className={'fa fa-stop'+(pomodoroState.running ? '' : ' disabled')}    onClick={pomodoroState.stop} />
  <i className={'fa fa-refresh'+(pomodoroState.running ? ' disabled' : '')} onClick={pomodoroState.reset({hard:true})} />
  &nbsp;&nbsp;
  <i className={'fa fa-cog'+(pomodoroState.running ? ' disabled' : '')} onClick={pomodoroState.toggleSettings} />
</div>))
