import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

export const PomodoroPattern = inject('pomodoroState')(observer(({pomodoroState,field,step=1}) => pomodoroState.settings ?
<div className="time-settings">
  <span className="settings-label"><Label value={'pomodoro.config.'+field} /></span>
  <span className="settings-input">
    <i className="fa fa-plus"  onClick={pomodoroState.adjust(field,step)} />
    <i className="fa fa-minus" onClick={pomodoroState.adjust(field,-step)} />
    <div>{pomodoroState.durations[field]}</div>
  </span>
</div> : null))
