import { action, observable } from 'mobx'

export class Settings {

  @observable trackingID = 'mag-buch-21'

  @observable tld    = 'de'
  @observable region = 'eu'
  @observable market = 'DE'

  @observable size = 110

  @action
  setTrackingID = trackingID => {
    this.trackingID = trackingID
  }

  @action
  setTLD = tld => {
    this.tld = tld
  }
  @action
  setRegion = region => {
    this.region = region
  }
  @action
  setMarket = market => {
    this.market = market
  }

  @action
  setSize = size => {
    this.size = size
  }
}

export default new Settings()
