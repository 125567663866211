import { action, observable, toJS } from 'mobx'

import { publish, subscribe } from 'sdc-publish-subscribe'

export class AdSlots {

  @observable slots = {}
  ads = []

  constructor() {
    subscribe('ads.amazon.data', this.updateAds)
    this.requestData()
  }

  @action
  updateAds = ads => {
    this.ads = ads || []
    this.loopDisplay()
  }

  @action
  addSlot = slot => {
    if (!this.slots[slot]) {
      this.fill(slot)
    }
  }
  @action
  delSlot = slot => {
    delete this.slots[slot]
  }

  loopDisplay = () => {
    if (this.ads.length) {
      this.showBooks()
      this.reschedule()
    } else {
      this.requestData()
    }
  }

  @action
  fill = slot => {
    this.slots = {
      ...this.slots,
      [slot] : this.ads.shift() || {}
    }
  }

  requestData = () => {
    publish('ads.amazon.load')
  }

  showBooks = () => {
    Object.keys(toJS(this.slots)).forEach(slot => {
      if (this.ads.length) {
        this.fill(slot)
      }
    })
  }

  reschedule = () => {
    setTimeout(this.loopDisplay, 60000)
  }

}

export const adSlots = new AdSlots()
